<template>
    <v-content class="error404">
    
        <div class="px-3 mt-12">
          <div class="py-10 text-center">
            <svg class="svg_maincolor" xmlns="http://www.w3.org/2000/svg" width="89.92" height="89.921" viewBox="0 0 89.92 89.921"><g id="magnifying-glass" transform="translate(0 0.001)"><path id="パス_1018" data-name="パス 1018" d="M222.031,29.92A24.759,24.759,0,0,0,206.407,35.4a1.317,1.317,0,0,0,1.646,2.057,22.152,22.152,0,0,1,13.977-4.9,22.4,22.4,0,1,1-22.4,22.4,22.152,22.152,0,0,1,4.9-13.977,1.317,1.317,0,0,0-2.057-1.646A24.759,24.759,0,0,0,197,54.951,25.031,25.031,0,1,0,222.031,29.92Z" transform="translate(-162.396 -24.665)" fill="#ff00b1"/><path id="パス_1019" data-name="パス 1019" d="M81.06,8.86A30.3,30.3,0,0,0,35.6,48.734l-5.771,5.771-.931-.931a3.954,3.954,0,0,0-6.363,4.5l-20.6,20.6a6.587,6.587,0,0,0,9.316,9.316l20.6-20.6a3.954,3.954,0,0,0,4.5-6.363l-.931-.931,5.771-5.771A30.3,30.3,0,0,0,81.06,8.86ZM3.793,86.127a3.955,3.955,0,0,1-.769-4.494L8.287,86.9a3.954,3.954,0,0,1-4.494-.768Zm30.69-21.375a1.317,1.317,0,0,1-1.861,0l-3.728-3.728A1.317,1.317,0,1,0,27.03,62.89l2.795,2.794L10.313,85.2,4.724,79.607,25.167,59.163a1.317,1.317,0,0,0,0-1.863,1.317,1.317,0,0,1,1.863-1.863l7.452,7.452a1.319,1.319,0,0,1,0,1.863Zm-.931-6.521-1.863-1.863L37.3,50.758c.3.322.6.641.91.953s.631.614.953.91ZM79.2,49.848a27.653,27.653,0,1,1,0-39.125A27.7,27.7,0,0,1,79.2,49.848Z" fill="#ff00b1"/></g></svg>
            <p class="mt-5 body-1" style="font-family: 'Oswald', sans-serif !important;">Page Not Found</p>
          </div>

          <div class="mt-4 px-4 text-center">
            <h1 class="body-1 font-weight-bold">お探しのページが見つかりませんでした</h1>
            <p class="mt-4 mb-10 fontsize13">あなたがアクセスしようとしたページは削除されたか、URLが変更されているため見つけることができませんでした。</p>
            <v-btn rounded class="maincolor-bg default_button" @click="$router.go(-1)">戻る</v-btn>
          </div>
        </div>
    
    </v-content>
</template>

<script>
export default {
    data() {
        return {

        };
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Oswald');
</style>
